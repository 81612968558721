import React, { useState, useEffect } from "react";
import { Navbar, Nav, Container } from "react-bootstrap";
import styled from "styled-components";
import { motion } from "framer-motion";

const StyledNavbar = styled(motion(Navbar))`
  background: #0a192f;
  padding: 1rem;
  position: fixed;
  width: 100%;
  z-index: 1000;
`;

const StyledNavLink = styled(motion(Nav.Link))`
  color: #64ffda !important;
  margin-right: 15px;
  &:hover {
    color: #fff !important;
  }
  &.active {
    color: #fff !important;
    font-weight: bold;
    border-bottom: 2px solid #64ffda;
  }
`;

const Header = () => {
  const [activeSection, setActiveSection] = useState("hero");

  useEffect(() => {
    const handleScroll = () => {
      const sections = ["hero", "about", "ofc_projects", "rksolutions","solutions", "blog", "services", "contact"];
      let currentSection = "hero";
      
      sections.forEach((section) => {
        const element = document.getElementById(section);
        if (element) {
          const rect = element.getBoundingClientRect();
          if (rect.top <= 100 && rect.bottom >= 100) {
            currentSection = section;
          }
        }
      });
      setActiveSection(currentSection);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <StyledNavbar 
      expand="lg" 
      variant="dark" 
      initial={{ opacity: 0, y: -50 }} 
      animate={{ opacity: 1, y: 0 }} 
      transition={{ duration: 0.8 }}
    >
      <Container>
        <Navbar.Brand 
          href="#hero" 
          style={{ color: "#64ffda" }}
          as={motion.a}
          whileHover={{ scale: 1.1 }}
        >
          Rabiul Khan
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ms-auto">
            {[
              { href: "#hero", label: "Home" },
              { href: "#about", label: "About" },
              { href: "#ofc_projects", label: "Projects" },
              { href: "#services", label: "Services" },
              { href: "#solutions", label: "Solutions" },
              { href: "#blog", label: "Blog" },
              { href: "#contact", label: "Contact" }
            ].map(({ href, label }) => (
              <StyledNavLink 
                key={href} 
                href={href} 
                className={activeSection === href.substring(1) ? "active" : ""} 
                whileHover={{ scale: 1.1 }}
              >
                {label}
              </StyledNavLink>
            ))}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </StyledNavbar>
  );
};

export default Header;