import React, { useState, useEffect } from "react";
import { Container, Row, Col, Card, Button, Modal } from "react-bootstrap";
import { motion } from "framer-motion";
import styled from "styled-components";

const BlogSection = styled.div`
  position: relative;
  padding: 80px 0;
  text-align: center;
  font-family: "Poppins", sans-serif;
  background: linear-gradient(135deg, rgba(30, 30, 30, 0.9), rgba(50, 50, 100, 0.9));
  overflow: hidden;
  color: white;
`;

const StyledModal = styled(Modal)`
  .modal-dialog {
    margin-top: 5% !important;
  }
  .modal-body {
    text-align: center;
  }
`;

// AdSense Component
const AdSenseAd = () => {
  return (
    <div className="text-center my-4">
      <ins
        className="adsbygoogle"
        style={{ display: "block" }}
        data-ad-client="ca-pub-7884037025290208"
        data-ad-slot="1684594670"
        data-ad-format="auto"
        data-full-width-responsive="true"
      ></ins>
      <script>(adsbygoogle = window.adsbygoogle || []).push({});</script>
    </div>
  );
};

const Blog = () => {
  const [blogs, setBlogs] = useState([]);
  const [visibleBlogs, setVisibleBlogs] = useState([]);
  const [showAll, setShowAll] = useState(false);
  const [selectedBlog, setSelectedBlog] = useState(null);
  const [showModal, setShowModal] = useState(false);

  // Fetch blogs from external JSON file
  useEffect(() => {
    fetch("/blogData.json")
      .then((response) => response.json())
      .then((data) => {
        const sortedBlogs = data.sort((a, b) => b.id - a.id);
        setBlogs(sortedBlogs);
        setVisibleBlogs(sortedBlogs.slice(0, 3));
      })
      .catch((error) => console.error("Error loading blogs:", error));
  }, []);

  // Toggle visible blogs
  const handleToggleBlogs = () => {
    setVisibleBlogs(showAll ? blogs.slice(0, 3) : blogs);
    setShowAll(!showAll);
  };

  // Open modal with selected blog details
  const handleShowModal = (blog) => {
    setSelectedBlog(blog);
    setShowModal(true);
  };

  return (
    <BlogSection id="blog">
      <Container>
        <h2 className="text-center mb-4">Latest Blogs</h2>
        <Row>
          {visibleBlogs.map((blog, index) => (
            <Col md={4} className="mb-4 d-flex" key={blog.id}>
              <motion.div whileHover={{ scale: 1.05 }} transition={{ duration: 0.3 }} className="w-100">
                <Card className="shadow-lg border-0 rounded d-flex flex-column w-100" style={{ backgroundColor: "#222b45", color: "white" }}>
                  <Card.Img
                    variant="top"
                    src={blog.image}
                    className="mx-auto d-block rounded mt-3"
                    style={{ width: "120px", height: "120px", objectFit: "cover" }}
                  />
                  <Card.Body className="d-flex flex-column justify-content-between">
                    <div>
                      <Card.Title className="text-uppercase fw-bold text-warning">{blog.title}</Card.Title>
                      <Card.Text className="text-white">Category: {blog.category}</Card.Text>
                    </div>
                    <Button className="mt-5" variant="outline-light" onClick={() => handleShowModal(blog)}>
                      Read
                    </Button>
                  </Card.Body>
                </Card>
              </motion.div>

              {/* AdSense Ad after 2nd card */}
              {index === 1 && <AdSenseAd />}
            </Col>
          ))}
        </Row>

        <Button variant="success" className="mt-4" onClick={handleToggleBlogs}>
          {showAll ? "Show Less" : "Load More"}
        </Button>
      </Container>

      {/* Modal for Blog Details */}
      <StyledModal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{selectedBlog?.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <img src={selectedBlog?.image} alt={selectedBlog?.title} className="img-fluid rounded mb-3" />
          <p><strong>Category:</strong> {selectedBlog?.category}</p>
          <p>{selectedBlog?.long_description}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>Close</Button>
        </Modal.Footer>
      </StyledModal>
    </BlogSection>
  );
};

export default Blog;
