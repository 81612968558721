import React, { useState, useEffect } from "react";
import { Container, Row, Col, Card, Button, Modal } from "react-bootstrap";
import { motion } from "framer-motion";
import styled, { keyframes } from "styled-components";

// Background Animation
const gradientAnimation = keyframes`
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
`;

const SolutionsSection = styled.div`
  position: relative;
  padding: 80px 0;
  text-align: center;
  font-family: "Poppins", sans-serif;
  background: linear-gradient(45deg, #2c3e50, #34495e, #2c3e50);
  background-size: 200% 200%;
  animation: ${gradientAnimation} 6s infinite alternate;
  overflow: hidden;
  color: white;
`;

const StyledModal = styled(Modal)`
  .modal-dialog {
    margin-top: 5% !important;
  }
  .modal-body {
    text-align: center;
  }
`;

const Solutions = () => {
  const [solutions, setSolutions] = useState([]);
  const [visibleSolutions, setVisibleSolutions] = useState([]);
  const [showAll, setShowAll] = useState(false);
  const [selectedSolution, setSelectedSolution] = useState(null);
  const [showModal, setShowModal] = useState(false);

  // Fetch solutions from JSON file
  useEffect(() => {
    fetch("/solutionData.json")
      .then((response) => response.json())
      .then((data) => {
        const sortedSolutions = data.sort((a, b) => b.id - a.id);
        setSolutions(sortedSolutions);
        setVisibleSolutions(sortedSolutions.slice(0, 3));
      })
      .catch((error) => console.error("Error loading solutions:", error));
  }, []);

  // Toggle visible solutions
  const handleToggleSolutions = () => {
    setVisibleSolutions(showAll ? solutions.slice(0, 3) : solutions);
    setShowAll(!showAll);
  };

  // Open modal with selected solution details
  const handleShowModal = (solution) => {
    setSelectedSolution(solution);
    setShowModal(true);
  };

  return (
    <SolutionsSection id="solutions">
      <Container>
        <h2 className="text-center mb-4">My Solutions</h2>
        <Row>
          {visibleSolutions.map((solution, index) => (
            <Col md={4} className="mb-4 d-flex" key={solution.id}>
              <motion.div whileHover={{ scale: 1.05 }} transition={{ duration: 0.3 }} className="w-100">
                <Card className="shadow-lg border-0 rounded d-flex flex-column w-100" style={{ backgroundColor: "#1e272e", color: "white" }}>
                  <Card.Img
                    variant="top"
                    src={`/solution_image/${solution.image}`} // Image from "public/solution_image/"
                    className="mx-auto d-block rounded mt-3"
                    style={{ width: "120px", height: "120px", objectFit: "cover" }}
                  />
                  <Card.Body className="d-flex flex-column justify-content-between">
                    <div>
                      <Card.Title className="text-uppercase fw-bold text-warning">{solution.service_name}</Card.Title>
                      <Card.Text className="text-white">{solution.short_description}</Card.Text>
                    </div>
                    <div className="d-flex justify-content-between mt-5">
                      <Button variant="outline-light" onClick={() => handleShowModal(solution)}>
                        View Details
                      </Button>
                      <Button variant="success" href={solution.link} target="_blank">
                        Go to Solution
                      </Button>
                    </div>
                  </Card.Body>
                </Card>
              </motion.div>
            </Col>
          ))}
        </Row>

        <Button variant="primary" className="mt-4" onClick={handleToggleSolutions}>
          {showAll ? "Show Less" : "Load More"}
        </Button>
      </Container>

      {/* Modal for Solution Details */}
      <StyledModal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{selectedSolution?.service_name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p><strong>Details:</strong> {selectedSolution?.description}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>Close</Button>
        </Modal.Footer>
      </StyledModal>
    </SolutionsSection>
  );
};

export default Solutions;
