import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { motion } from "framer-motion";
import styled from "styled-components";
import { FaLinkedin, FaGithub, FaTwitter, FaEnvelope } from "react-icons/fa";

const FooterWrapper = styled.footer`
  background: #0a192f;
  color: white;
  padding: 50px 0;
  font-family: "Poppins", sans-serif;
  text-align: center;
`;

const FooterList = styled.ul`
  list-style: none;
  padding: 0;
`;

const FooterItem = styled(motion.li)`
  margin: 8px 0;
  font-size: 16px;
`;

const SocialIcon = styled(motion.a)`
  margin: 0 10px;
  color: white;
  font-size: 24px;
  transition: color 0.3s;

  &:hover {
    color: #64ffda;
  }
`;

const ServicesSection = styled.section`
  background: #0a192f;
  color: white;
  padding: 80px 0;
  text-align: center;
`;

const ServiceCard = styled(motion.div)`
  background: #112240;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 4px 10px rgba(0, 255, 170, 0.2);
  transition: transform 0.3s;
  &:hover {
    transform: translateY(-5px);
  }
`;

const Services = () => {
  return (
    <ServicesSection id="services">
      <Container>
        <h2 className="mb-4">My Services</h2>
        <Row>
          <Col md={4}>
            <ServiceCard whileHover={{ scale: 1.05 }}>
              <h4>Web Development</h4>
              <p>Building responsive and dynamic web applications.</p>
            </ServiceCard>
          </Col>
          <Col md={4}>
            <ServiceCard whileHover={{ scale: 1.05 }}>
              <h4>SaaS Development</h4>
              <p>Creating scalable Software as a Service solutions.</p>
            </ServiceCard>
          </Col>
          <Col md={4}>
            <ServiceCard whileHover={{ scale: 1.05 }}>
              <h4>API & Automation</h4>
              <p>Developing APIs and workflow automation.</p>
            </ServiceCard>
          </Col>
        </Row>
        <Button variant="outline-light" className="mt-4" href="#contact">Work With Me</Button>
      </Container>
    </ServicesSection>
  );
};



export default Services;
