import React, { useState } from "react";
import { Container, Row, Col, Card, Button, Modal } from "react-bootstrap";
import { motion } from "framer-motion";
import styled from "styled-components";
import ProfilePic from "../images/profile_pic.jpeg";
// import scheduler from "../images/scheduler.png";
// import archive from "../images/archive.png";
// import nrcms from "../images/nrcms.png";
// import nmr from "../images/nmr.png";
// import online_gfx from "../images/online_gfx.png";
// import rundown from "../images/rundown.png";
// import casparcg from "../images/casparcg.png";
// import dam from "../images/dam.png";

import scheduler from "../images/scheduler.jpg";
import archive from "../images/archive.jpg";
import nrcms from "../images/nrcms.jpg";
import nmr from "../images/nmr.jpg";
import online_gfx from "../images/online_gfx.jpg";
import rundown from "../images/rundown.jpg";
import casparcg from "../images/casparcg.jpg";
import dam from "../images/dam.jpg";
import invoice from "../images/invoice.jpg";
import teleprompter from "../images/teleprompter.jpg";

const Section = styled.section`
  padding: 80px 0;
  text-align: center;
  position: relative;
  font-family: "Poppins", sans-serif;
  overflow: hidden;
`;

const AnimatedBackground = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(135deg, rgba(20, 20, 20, 0.8), rgba(40, 40, 80, 0.8));
  z-index: -1;
  animation: bgAnimation 10s infinite alternate;

  @keyframes bgAnimation {
    0% { background-position: 0% 0%; }
    100% { background-position: 100% 100%; }
  }
`;

const SectionTitle = styled.h2`
  color:rgb(255, 255, 255);
  font-size: 36px;
  font-weight: 700;
`;

const ProjectCard = styled(motion(Card))`
  background: #1c2b44;
  color: white;
  border: none;
  margin-bottom: 20px;
  transition: transform 0.3s, box-shadow 0.3s;
  &:hover {
    transform: scale(1.05);
    box-shadow: 0px 0px 15px rgba(100, 255, 218, 0.5);
  }
  .card-img-top {
    width: 200px;
    height: 100px;
    object-fit: cover;
    border-radius: 10%;
    display: block;
    margin: 15px auto;
  }
`;

const projectsData = {
  office: [
    { 
      id: 1, 
      title: "TV Scheduler", 
      img: scheduler, 
      description: "A 24/7 automated TV scheduling system with seamless integration for advertisements, overlays, and real-time time calculation. Supports billing automation for accurate financial tracking." 
    },
    { 
      id: 2, 
      title: "Digital Asset Management (DAM)", 
      img: dam, 
      description: "A centralized system for organizing, storing, and retrieving digital assets, ensuring efficient content management and workflow optimization." 
    },
    { 
      id: 3, 
      title: "Playout Integration", 
      img: casparcg, 
      description: "Seamless integration with CasparCG playout for automated scheduling, live broadcast handling, and dynamic overlay graphics." 
    },
    { 
      id: 4, 
      title: "Rundown System", 
      img: rundown, 
      description: "A real-time rundown management system enabling precise control over live broadcasts, ensuring smooth transitions and on-the-fly adjustments." 
    },
    { 
      id: 5, 
      title: "Newsroom Computer System (NRCMS)", 
      img: nrcms, 
      description: "A comprehensive newsroom content management system (CMS) designed for streamlined news production, collaboration, and automation." 
    },
    { 
      id: 6, 
      title: "Online Graphics (GFX)", 
      img: online_gfx, 
      description: "A real-time graphics solution for television broadcasts, including tickers, lower-thirds, aston effects, channel branding, and dynamic overlays." 
    },
    { 
      id: 7, 
      title: "Media Handler", 
      img: nmr, 
      description: "A professional-grade media management tool with advanced features like looping, queuing, pausing, and chained playback for seamless news footage handling." 
    },
    { 
      id: 8, 
      title: "Teleprompter System", 
      img: teleprompter, 
      description: "An intuitive teleprompter solution allowing news presenters to control and navigate scripts efficiently during live broadcasts." 
    },
    { 
      id: 9, 
      title: "Archive Management", 
      img: archive, 
      description: "A structured digital archive system for storing, retrieving, and managing media content with metadata tagging and efficient search capabilities." 
    },
    { 
      id: 10, 
      title: "Billing Automation", 
      img: invoice, 
      description: "A fully automated billing system that generates accurate invoices from as-run logs, ensuring precise financial reporting for broadcasters." 
    }
  ],
};


const Projects = () => {
  const [selectedProject, setSelectedProject] = useState(null);

  return (
    <>
      {/* Office Projects Section */}
      <Section id="ofc_projects">
        <AnimatedBackground />
        <Container>
          <SectionTitle>Broadcast Automation Systems</SectionTitle>
          <Row>
            {projectsData.office.map((project) => (
              <Col md={4} key={project.id}>
                <ProjectCard whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }} transition={{ duration: 0.3 }}>
                  <Card.Img variant="top" src={project.img} />
                  <Card.Body>
                    <Card.Title>{project.title}</Card.Title>
                    <Button variant="primary" onClick={() => setSelectedProject(project)}>View Details</Button>
                  </Card.Body>
                </ProjectCard>
              </Col>
            ))}
          </Row>
        </Container>
      </Section>

      {/* Modal for Viewing Details */}
      <Modal show={!!selectedProject} onHide={() => setSelectedProject(null)} centered>
        <Modal.Header closeButton>
          <Modal.Title>{selectedProject?.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{selectedProject?.description}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setSelectedProject(null)}>Close</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Projects;
