import React from "react";
import Header from "./components/Header";
import Hero from "./components/Hero";
import About from "./components/About";
import Projects from "./components/Projects";
import Blog from "./components/Blog";
import Contact from "./components/Contacts";
import Footer from "./components/Footer";
import Services from "./components/Services";
import Sollutions from "./components/Solutions";
import "bootstrap/dist/css/bootstrap.min.css";
import Solutions from "./components/Solutions";

const App = () => {
  return (
    <>
      <Header />
      <Hero />
      <About />
      <Projects />
      <Services />
      <Solutions />
      <Blog />
      <Contact />
      <Footer />
    </>
  );
};

export default App;
