import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { motion } from "framer-motion";
import styled, { keyframes } from "styled-components";
import ProfilePic from "../images/profile_pic.jpg"; // ✅ Import your image properly

const floatingAnimation = keyframes`
  0% { transform: translateY(0px); }
  50% { transform: translateY(-8px); }
  100% { transform: translateY(0px); }
`;

const Section = styled.section`
  padding: 80px 0;
  text-align: center;
  position: relative;
  font-family: "Poppins", sans-serif;
`;

const AnimatedBackground = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(135deg, rgba(10, 10, 10, 0.9), rgba(30, 30, 60, 0.9));
  z-index: -1;
  animation: bgAnimation 10s infinite alternate;

  @keyframes bgAnimation {
    0% { background-position: 0% 0%; }
    100% { background-position: 100% 100%; }
  }
`;

const SectionTitle = styled.h2`
  color:rgb(255, 255, 255);
  font-size: 36px;
  font-weight: 700;
  margin-bottom: 30px;
`;

const ProfileImage = styled(motion.img)`
  width: 250px;
  height: 250px;
  border-radius: 50%;
  border: 5px solid #64ffda;
  margin-bottom: 20px;
`;

const Description = styled.p`
  color: white;
  font-size: 18px;
  text-align: left;
  padding: 0 20px;
`;

const SkillItem = styled(motion.li)`
  background: rgba(255, 255, 255, 0.1);
  padding: 12px;
  margin: 10px 0;
  border-radius: 5px;
  list-style: none;
  text-align: left;
  font-size: 16px;
  color: #64ffda;
  position: relative;
  cursor: pointer;
  transition: background 0.3s ease;
  animation: ${floatingAnimation} 4s ease-in-out infinite;

  &:hover {
    background: rgba(255, 255, 255, 0.2);
  }
`;

// Tooltip for Hover
const Tooltip = styled(motion.div)`
  position: absolute;
  left: 100%;
  top: 50%;
  transform: translateY(-50%);
  background: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 8px 12px;
  border-radius: 5px;
  font-size: 14px;
  white-space: nowrap;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease;
  z-index: 10;

  ${SkillItem}:hover & {
    opacity: 1;
    visibility: visible;
  }
`;

// Skills Data with Descriptions
const skills = [
  {
    name: "✔ Broadcast Automation (Scheduler, CasparCG playout integration, Online GFX, Rundown, NewsRoom Automation)",
    description: "Experience in building automation tools for TV and live broadcasting.",
  },
  {
    name: "✔ Web Development (HTML5, CSS3, Bootstrap, Javascript, ReactJS, Django, Raw PHP)",
    description: "Full-stack web development with modern frameworks and libraries.",
  },
  {
    name: "✔ Desktop Application (JavaFX, Swing)",
    description: "Any kind of Desktop Solutions.",
  },
  {
    name: "✔ SaaS Development (REST APIs)",
    description: "Building scalable cloud-based software solutions.",
  },
  {
    name: "✔ Database Management (MySQL, PostgreSQL)",
    description: "Database design, optimization, and management.",
  },
  {
    name: "✔ Agile & Scrum-based Development",
    description: "Following agile methodologies for efficient project management.",
  },
];

const AboutMe = () => {
  return (
    <Section id="about">
      <AnimatedBackground />
      <Container>
        <SectionTitle>About Me</SectionTitle>
        <Row className="align-items-center">
          {/* Profile Image & Description */}
          <Col md={6} className="text-center">
            <ProfileImage 
              src={ProfilePic}  
              alt="Rabiul Khan"
              initial={{ opacity: 0, scale: 0.8 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ duration: 1 }}
            />
            <Description>
              I am a **Software Engineer** with **10+ years** of experience in 
              **Broadcast Automation & SaaS Development**.  
              <br /><br />
              I specialize in **TV Scheduler, Casparcg Playout integration, Digital Asset Management(DAM),Rundown, NewsRoom Automation with Online Graphics** for the **broadcast industry**, 
              while also building **scalable SaaS solutions**.  
              <br /><br />
              I also provide services on WEB, Desktop Based Solutions, SAAS Development.Click hire me to connect with me.
            </Description>
          </Col>

          {/* Skills List */}
          <Col md={6}>
            <h3 className="text-white text-start">Technical Skills</h3>
            <ul className="p-0">
              {skills.map((skill, index) => (
                <SkillItem 
                  key={index}
                  initial={{ opacity: 0, x: -50 }}
                  animate={{ opacity: 1, x: 0 }}
                  transition={{ duration: 0.5, delay: index * 0.2 }}
                >
                  {skill.name}
                  <Tooltip initial={{ opacity: 0, scale: 0.8 }} animate={{ opacity: 1, scale: 1 }}>
                    {skill.description}
                  </Tooltip>
                </SkillItem>
              ))}
            </ul>
          </Col>
        </Row>
      </Container>
    </Section>
  );
};

export default AboutMe;
