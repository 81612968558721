import { motion } from "framer-motion";
import { FaArrowDown } from "react-icons/fa";
import styled, { keyframes } from "styled-components";

// Animated Background
const floatingBg = keyframes`
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
`;

// Floating animation for orbs
const floatAnimation = keyframes`
  0% { transform: translateY(0px) rotate(0deg); }
  50% { transform: translateY(-20px) rotate(20deg); }
  100% { transform: translateY(0px) rotate(0deg); }
`;

const HeroContainer = styled.div`
  width: 100%; /* Change from 100vw to 100% */
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: relative;
  color: white;
  overflow: hidden;
  background: linear-gradient(-45deg, #0f0c29, #302b63, #24243e, #141e30);
  background-size: 300% 300%;
  animation: ${floatingBg} 8s ease infinite;
`;


// Floating Orbs with dynamic positions
const FloatingOrb = styled(motion.div)`
  position: absolute;
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  background: radial-gradient(circle, rgba(255, 255, 255, 0.3) 0%, transparent 60%);
  border-radius: 50%;
  animation: ${floatAnimation} 6s ease-in-out infinite;
  filter: blur(5px);

  @media (max-width: 768px) {
    width: ${({ size }) => size * 0.7}px;
    height: ${({ size }) => size * 0.7}px;
  }
`;

const Title = styled(motion.h1)`
  font-size: clamp(2rem, 5vw, 3rem);
  font-weight: bold;
  margin-bottom: 0.5rem;
  max-width: 90%;
`;

const Subtitle = styled(motion.p)`
  font-size: clamp(1rem, 3vw, 1.5rem);
  margin-bottom: 1.5rem;
  max-width: 80%;
`;

const ButtonWrapper = styled(motion.a)`
  display: inline-block;
  background-color: #007bff;
  color: white;
  padding: 12px 24px;
  font-size: 1.2rem;
  border-radius: 8px;
  text-decoration: none;
  transition: 0.3s;
  
  &:hover {
    background-color: #0056b3;
    transform: scale(1.05);
  }
`;

const ScrollIndicator = styled(motion.div)`
  position: absolute;
  bottom: 30px;
  left: 50%;
  transform: translateX(-50%);
`;

const Hero = () => {
  return (
    <HeroContainer id="hero">
      {/* Floating Orbs (Dynamic Positions for better responsiveness) */}
      <FloatingOrb size={80} style={{ top: "15%", left: "5%" }} animate={{ y: [0, 20, 0] }} transition={{ repeat: Infinity, duration: 6, ease: "easeInOut" }} />
      <FloatingOrb size={60} style={{ top: "70%", left: "85%" }} animate={{ y: [0, 30, 0] }} transition={{ repeat: Infinity, duration: 8, ease: "easeInOut" }} />
      <FloatingOrb size={100} style={{ top: "45%", left: "50%" }} animate={{ y: [0, 25, 0] }} transition={{ repeat: Infinity, duration: 7, ease: "easeInOut" }} />

      {/* Hero Text */}
      <Title initial={{ y: -50, opacity: 0 }} animate={{ y: 0, opacity: 1 }} transition={{ duration: 1 }}>
        Hi, I'm Rabiul Khan
      </Title>
      <Subtitle initial={{ y: 50, opacity: 0 }} animate={{ y: 0, opacity: 1 }} transition={{ duration: 1.2 }}>
        Software Engineer | Broadcast Automation Specialist | Freelancer
      </Subtitle>

      {/* Fixed Navigation Issue */}
      <ButtonWrapper href="#contact" whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }}>
        Work With Me
      </ButtonWrapper>

      {/* Animated Scroll Indicator */}
      <ScrollIndicator
        animate={{ y: [0, 10, 0], opacity: [0, 1, 0.8, 1] }}
        transition={{ repeat: Infinity, duration: 1.5, ease: "easeInOut" }}
      >
        <FaArrowDown size={30} color="#ffffff" />
      </ScrollIndicator>
    </HeroContainer>
  );
};

export default Hero;
