import React, { useState } from "react";
import { Container, Row, Col, Form, Button, Alert, Spinner } from "react-bootstrap";
import { motion } from "framer-motion";
import { FaEnvelope } from "react-icons/fa";
import styled from "styled-components";
import "../styles/contact.css";
//import { Form, FloatingLabel, Button, Spinner, Alert } from "react-bootstrap";

const ContactSection = styled.div`
  position: relative;
  padding: 80px 0;
  text-align: center;
  font-family: "Poppins", sans-serif;
  background: linear-gradient(135deg, rgba(20, 20, 20, 0.9), rgba(40, 40, 80, 0.9));
  overflow: hidden;
  color: white;
`;

const AnimatedBackground = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background: linear-gradient(135deg, rgba(20, 20, 20, 0.8), rgba(40, 40, 80, 0.8));
  animation: bgAnimation 10s infinite alternate;

  @keyframes bgAnimation {
    0% { background-position: 0% 0%; }
    100% { background-position: 100% 100%; }
  }
`;

const FloatingLabel = styled.div`
  position: relative;
  margin-bottom: 20px;

  label {
    position: absolute;
    left: 15px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 16px;
    color: rgba(255, 255, 255, 0.7);
    transition: all 0.3s ease-in-out;
    background: transparent;
    padding: 2px 6px;
    border-radius: 5px;
  }

  input,
  textarea {
    width: 100%;
    padding: 12px;
    border: 2px solid transparent;
    border-bottom: 2px solid rgba(255, 255, 255, 0.5);
    background: transparent;
    color: white;
    font-size: 16px;
    transition: all 0.3s ease-in-out;

    &:focus {
      border-bottom: 2px solid #64ffda;
      outline: none;
    }
  }

  input:focus ~ label,
  textarea:focus ~ label,
  input:not(:placeholder-shown) ~ label,
  textarea:not(:placeholder-shown) ~ label {
    top: -8px;
    left: 12px;
    font-size: 14px;
    font-weight: bold;
    color: #64ffda;
    background: linear-gradient(135deg, rgba(20, 255, 180, 0.3), rgba(100, 255, 218, 0.3));
    padding: 3px 8px;
  }

  /* Gradient underline animation */
  input:focus,
  textarea:focus {
    border-bottom: 2px solid transparent;
    background: linear-gradient(to right, #64ffda, #20ffb4) no-repeat;
    background-size: 100% 2px;
    background-position: bottom;
  }
`;


const Contact = () => {
  const [formData, setFormData] = useState({ name: "", email: "", message: "" });
  const [response, setResponse] = useState({ type: "", message: "" });
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setResponse({ type: "", message: "" });

    try {
      const res = await fetch("https://rabiulkhan.com/backend/contact.php", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(formData),
      });

      const result = await res.json();
      setLoading(false);

      if (result.status === "success") {
        setResponse({ type: "success", message: result.message });
        setFormData({ name: "", email: "", message: "" });
      } else {
        setResponse({ type: "danger", message: result.message });
      }
    } catch (error) {
      setLoading(false);
      setResponse({ type: "danger", message: "An error occurred. Please try again." });
    }
  };

  return (
    <ContactSection id="contact">
      <AnimatedBackground />
      <Container fluid className="py-5">
        <h2 className="text-center">Contact Me</h2>
        <Row className="mt-4 align-items-center justify-content-center">
          <Col md={5} className="d-flex justify-content-center">
            <motion.div initial={{ opacity: 0, x: -50 }} animate={{ opacity: 1, x: 0 }} transition={{ duration: 1 }}>
              <a href="mailto:rksolutionservice007@gmail.com" className="btn btn-primary btn-lg">
                <FaEnvelope size={24} className="me-2" /> Email Me Directly
              </a>
            </motion.div>
          </Col>
          <Col md={5}>
          <motion.div initial={{ opacity: 0, x: 50 }} animate={{ opacity: 1, x: 0 }} transition={{ duration: 1 }}>
            <Form onSubmit={handleSubmit} className="p-4 rounded shadow-lg bg-dark text-light">
              {response.message && <Alert variant={response.type}>{response.message}</Alert>}

              <FloatingLabel controlId="name">
                <Form.Control type="text" name="name" value={formData.name} onChange={handleChange} placeholder=" " required />
                <Form.Label>Name</Form.Label>
              </FloatingLabel>

              <FloatingLabel controlId="email">
                <Form.Control type="email" name="email" value={formData.email} onChange={handleChange} placeholder=" " required />
                <Form.Label>Email</Form.Label>
              </FloatingLabel>

              <FloatingLabel controlId="message">
                <Form.Control as="textarea" rows={4} name="message" value={formData.message} onChange={handleChange} placeholder=" " required />
                <Form.Label>Message</Form.Label>
              </FloatingLabel>

              <Button variant="success" type="submit" className="w-100 mt-3" disabled={loading}>
                {loading ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : "Send Message"}
              </Button>
            </Form>
          </motion.div>
          </Col>
        </Row>
      </Container>
    </ContactSection>
  );
};

export default Contact;