import React, { useState, useEffect } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { motion } from "framer-motion";
import styled from "styled-components";
import { FaLinkedin, FaGithub, FaYoutube, FaEnvelope, FaArrowUp } from "react-icons/fa";

const FooterWrapper = styled.footer`
  background: #0a192f;
  color: white;
  padding: 50px 0;
  font-family: "Poppins", sans-serif;
  text-align: center;
  position: relative;
`;

const FooterList = styled.ul`
  list-style: none;
  padding: 0;
`;

const FooterItem = styled(motion.li)`
  margin: 8px 0;
  font-size: 16px;
`;

const SocialIcon = styled(motion.a)`
  margin: 0 10px;
  color: white;
  font-size: 24px;
  transition: color 0.3s;

  &:hover {
    color: #64ffda;
  }
`;

const ScrollToTop = styled(motion.div)`
  position: fixed;
  bottom: 20px;
  right: 20px;
  background: #64ffda;
  color: #0a192f;
  padding: 10px;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

  &:hover {
    background: #fff;
    color: #0a192f;
  }
`;

const Footer = () => {
  const [showScroll, setShowScroll] = useState(false);

  useEffect(() => {
    const checkScroll = () => {
      setShowScroll(window.scrollY > 200);
    };
    window.addEventListener("scroll", checkScroll);
    return () => window.removeEventListener("scroll", checkScroll);
  }, []);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <FooterWrapper>
      <Container>
        <Row>
          {/* Column 1 - Navigation Links */}
          <Col md={4} className="text-center">
            <h5>Quick Links</h5>
            <FooterList>
              <FooterItem whileHover={{ scale: 1.1 }}>
                <a href="#hero" className="text-white text-decoration-none">Home</a>
              </FooterItem>
              <FooterItem whileHover={{ scale: 1.1 }}>
                <a href="#about" className="text-white text-decoration-none">About</a>
              </FooterItem>
              <FooterItem whileHover={{ scale: 1.1 }}>
                <a href="#ofc_projects" className="text-white text-decoration-none">Projects</a>
              </FooterItem>
              <FooterItem whileHover={{ scale: 1.1 }}>
                <a href="#services" className="text-white text-decoration-none">Services</a>
              </FooterItem>
              <FooterItem whileHover={{ scale: 1.1 }}>
                <a href="#blog" className="text-white text-decoration-none">Blog</a>
              </FooterItem>
              <FooterItem whileHover={{ scale: 1.1 }}>
                <a href="#contact" className="text-white text-decoration-none">Contact</a>
              </FooterItem>
            </FooterList>
          </Col>

          {/* Column 2 - Social Links */}
          <Col md={4} className="text-center">
            <h5>Follow Me</h5>
            <div>
              <SocialIcon href="https://www.linkedin.com/in/rabiul-khan-2a2b8496/" whileHover={{ scale: 1.2 }}>
                <FaLinkedin />
              </SocialIcon>
              <SocialIcon href="https://github.com/rabiulkhan07" whileHover={{ scale: 1.2 }}>
                <FaGithub />
              </SocialIcon>
              <SocialIcon href="https://www.youtube.com/@rabiulkhan1397/playlists" whileHover={{ scale: 1.2 }}>
                <FaYoutube />
              </SocialIcon>
              <SocialIcon href="mailto:rksolutionservice007@gmail.com" whileHover={{ scale: 1.2 }}>
                <FaEnvelope />
              </SocialIcon>
            </div>
          </Col>

          {/* Column 3 - AdSense + Hire Me Section */}
          <Col md={4} className="text-center">
            <h5>Advertisement</h5>
            {/* Google AdSense Ad Slot */}
            <div style={{ display: "flex", justifyContent: "center", marginBottom: "10px" }}>
              <ins 
                className="adsbygoogle"
                style={{ display: "block" }}
                data-ad-client="ca-pub-7884037025290208"
                data-ad-slot="1684594670"
                data-ad-format="auto"
                data-full-width-responsive="true"
              ></ins>
            </div>

            <h5>© 2025 Rabiul Khan</h5>
            <p>Designed & Built by Me</p>
            <Button variant="outline-light" href="#contact">Hire Me</Button>
          </Col>
        </Row>
      </Container>

      {showScroll && (
        <ScrollToTop onClick={scrollToTop} whileHover={{ scale: 1.2 }}>
          <FaArrowUp />
        </ScrollToTop>
      )}
    </FooterWrapper>
  );
};

export default Footer;
